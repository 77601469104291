import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useRouterChange = () => {
  const router = useRouter()
  const [isRouterChange, setRouterChange] = useState(false)

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const currentPath = `/${router.locale}${router.asPath}`.split('?')[0].toLowerCase()
      const nextPath = url.split('?')[0].toLowerCase()
      if (currentPath !== nextPath) {
        setRouterChange(true)
      }
    }

    const handleRouteChangeComplete = () => {
      setRouterChange(false)
    }

    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router])

  return { isRouterChange }
}
