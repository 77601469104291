import { TealiumService } from '@foundation/analytics/tealium'
import { PAGE_TYPES } from '@foundation/analytics/tealium/pageTypes'
import { getUserToken, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import { SiteInfo } from '@redux/reducers'
import { useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import config from '../../configs/config.base'

interface AnalyticsTealiumProps {
  mySite: SiteInfo
  enableAnalytics: boolean
  enableTealium: boolean
}

export const AnalyticsTealium: React.FC<AnalyticsTealiumProps> = ({ mySite, enableAnalytics, enableTealium }) => {
  const query = useSearchParams()
  const { ...analyticsCommonData } = useAnalyticsData(PAGE_TYPES.home)
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    if (window && window.tealium_data2track) {
      setScriptLoaded(true)
    }
  }, [])

  useEffect(() => {
    // init analytics
    const Analytics = () => {
      analyticsCommonData.userToken = getUserToken()
      return new TealiumService(mySite, Date.now().toString(), analyticsCommonData)
    }

    if (!scriptLoaded && config.enableAnalytics && mySite) {
      enableAnalytics && Analytics()
      enableTealium && window.dispatchEvent(new Event('tealiumInit'))
    }
  }, [query, analyticsCommonData, mySite, scriptLoaded, enableAnalytics, enableTealium])

  return null
}

export default AnalyticsTealium
