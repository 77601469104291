import { APP_MAIN_CONTAINER_CLASSNAME, APP_MAIN_CONTAINER_ID } from '@constants/ui'
import BaseLayoutSkeleton from '@layouts/BaseLayout/BaseLayoutSkeleton'
import dynamic from 'next/dynamic'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoginGuard from '../../foundation/guard/LoginGuard'
import { AppMain, AppWrapper } from './components/BaseLayout.style'
//UI
// import { TealiumService } from '../../foundation/analytics/tealium'
import { openDrawerMegaMenuSelector, openDrawerSearchSelector, showFullPageLoaderSelector } from '@features/ui/selector'
import config from '../../configs'
import { getPreviewSelector } from '../../features/preview/selector'
//Foundation libraries
//UI
//Custom libraries
import { setOpenDrawerNewsLetter } from '@features/ui/action'
import styled from '@mui/material/styles/styled'
// Tealium
//hooks
import { ExternalScripts } from '@components/ExternalScripts/ExternalScripts'
import { usePrescriptionLenses } from '@components/PrescriptionLenses/PrescriptionLensesContext'
import { CAROUSEL_TEMPLATE_ID, LIST_TEMPLATE_ID } from '@components/TrustPilot/constants'
import { VideoPlayerIconSymbols } from '@components/common/components/VideoPlayer/VideoPlayerControlsIcons'
import useBreakpoints from '@hooks/useBreakpoints'
import { useRouterChange } from '@hooks/useRouterChange/useRouterChange'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { usePageType } from '../../foundation/hooks/usePageType'
import { useSite } from '../../foundation/hooks/useSite'
import { PAGE_TYPES } from '@utils/httpHeadersUtils'
import SocialLoginProvider from '../../pages_views/SignUp/provider'
import { BaseLayoutHead } from './components/BaseLayoutHead'
import Footer from '../Footer'
import { FullPageLoader } from '@components/FullPageLoader'
import Container from '@components/UI/Container'
import ReturningCustomerLoginButton from './components/ReturningCustomerLoginButton'
import { ACCOUNT } from '@constants/routes'
import Header from '@layouts/Header/Header'

const FrameAdvisor = dynamic(() => import('@components/FrameGenius/FrameAdvisor'), {
  ssr: false,
})
const SizeAdvisor = dynamic(() => import('@components/FrameGenius/SizeAdvisor'), {
  ssr: false,
})

const TrustpilotWidget = dynamic(() => import('@components/TrustPilot/TrustpilotWidget'), {
  ssr: false,
})

const Prescription = dynamic(() => import('@components/PrescriptionLenses/Prescription').then(m => m.Prescription), {
  ssr: false,
})

const ErrorMessageSnackbar = dynamic(() => import('@components/message-snackbar/ErrorMessageSnackbar'), {
  ssr: false,
})

const TopBar = dynamic(() => import('../../components/TopBar/TopBar'), {
  ssr: false,
})

const StyledContent = styled('div', {
  name: 'App',
  slot: 'Content',
  shouldForwardProp: prop => prop !== 'pageType' && prop !== 'orderConfirmationPage',
})<{ pageType?: string; orderConfirmationPage?: boolean }>(({ theme, pageType, orderConfirmationPage }) => ({
  width: '100%',
  minHeight: pageType === 'cart' ? 'calc(100vh - 15rem)' : '100vh',
  backgroundColor: pageType === 'checkout' && !orderConfirmationPage ? 'unset' : 'unset',

  [theme.breakpoints.up('sm')]: {
    minHeight: pageType === 'cart' ? 'calc(100vh - 40rem)' : 'calc(100vh - 56px)',
  },

  [theme.breakpoints.up('md')]: {
    minHeight: pageType === 'cart' ? 'calc(100vh - 35rem)' : 'calc(100vh - 56px)',
  },
}))

const AppMainWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const isSearchDrawerOpen = useSelector(openDrawerSearchSelector)
  const megaMenuDrawer = useSelector(openDrawerMegaMenuSelector)
  const openElements = isSearchDrawerOpen || megaMenuDrawer.isOpen /*TODO RESTORE|| vMisOpen */
  const router = useRouter()
  const contactLensesPage = router.asPath.includes('contact-lenses')

  return (
    <AppMain
      id={APP_MAIN_CONTAINER_ID}
      className={APP_MAIN_CONTAINER_CLASSNAME}
      openElements={openElements}
      contactLensesPage={contactLensesPage}
    >
      {children}
    </AppMain>
  )
}

const BaseLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const getPreview = useSelector(getPreviewSelector)
  const showFullPageLoader = useSelector(showFullPageLoaderSelector)
  const { isRouterChange } = useRouterChange()
  const topRef = useRef<HTMLDivElement>(null)
  const { mySite } = useSite()
  const { pageType } = usePageType()
  const { isDesktop } = useBreakpoints()
  const [isVisibleTopBar, setIsVisibleTopBar] = useState<boolean>(false)
  const query = useSearchParams()

  const isMyAccount = router.pathname.includes(ACCOUNT)

  const orderConfirmationPage = router.asPath.includes('order-confirmation')

  const { isOpenPrescriptionLensesForm } = usePrescriptionLenses()

  useEffect(() => {
    document.querySelector(`#${config.name}-app__main`)?.scrollTo({ top: 0 })

    setIsVisibleTopBar(query.get('onBehalfSession') === 'true' || localStorage.getItem('beHalfTrue') === 'true')
  }, [isRouterChange, query])

  useEffect(() => {
    dispatch(setOpenDrawerNewsLetter(false))
  }, [dispatch, mySite])

  return (
    <AppWrapper data-testid="app-wrapper" isPreview={getPreview.isEnabled}>
      <BaseLayoutHead />
      <ExternalScripts mySite={mySite} />
      {isVisibleTopBar && <TopBar />}
      <AppMainWrapper>
        <Header />
        {pageType === PAGE_TYPES.HOME.toLowerCase() && <ReturningCustomerLoginButton />}
        <section ref={topRef} className="full-viewport-height" id="main-viewport">
          <LoginGuard />
          <StyledContent pageType={pageType} className={'main'}>
            <SocialLoginProvider>
              {isRouterChange && !isMyAccount ? <BaseLayoutSkeleton /> : children}
            </SocialLoginProvider>
          </StyledContent>
        </section>
        {pageType === 'home' && config.trustPilotEnabled && (
          <Container>
            <TrustpilotWidget
              height={isDesktop ? '140px' : '492px'}
              widgetTemplateId={isDesktop ? CAROUSEL_TEMPLATE_ID : LIST_TEMPLATE_ID}
            />
          </Container>
        )}
        <Footer />
        <ErrorMessageSnackbar />
        <FrameAdvisor />
        <SizeAdvisor />
        {isOpenPrescriptionLensesForm && <Prescription />}
      </AppMainWrapper>
      <div id="vmPortal"></div>
      <VideoPlayerIconSymbols />
      <ExternalScripts trustPilot />
      {showFullPageLoader && <FullPageLoader />}
    </AppWrapper>
  )
}

export default BaseLayout
