import styled from '@mui/material/styles/styled'

export const StyledFooterContainer = styled('div', {
  name: 'FooterContainer',
})(() => ({
  contentVisibility: 'auto',
}))

export const StyledFooterWrapper = styled('footer', {
  name: 'FooterWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.background[1],
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10, 0),
  },
}))

export const StyledCopyrightWrapper = styled('div', {
  name: 'FooterCopyrightWrapper',
})(({ theme }) => ({
  padding: theme.spacing(0, 4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 8),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(32, 16, 0),
  },
}))

export const StyledFooterRow = styled('div', {
  name: 'FooterRow',
})(({ theme }) => ({
  padding: theme.spacing(20, 0, 15),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const StyledFooterLogo = styled('div', {
  name: 'FooterLogo',
})(() => ({
  maxWidth: 200,
  width: '100%',
}))

export const StyledDesktopWrapper = styled('div', {
  name: 'FooterDesktopWrapper',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))
